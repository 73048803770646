*{
  margin:0; padding:0;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: 300;
}

html {
  scroll-behavior: smooth;
}


body{
  position: relative;
  overflow-x: hidden;
}
