h1,
h2,
h3,
h4,
h5,
h6 {}
a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #333;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
max-width: 100%;
height: auto;
}
section {
    padding: 60px 0;
   /* min-height: 100vh;*/
}
.footer {
background: white;
padding-top: 80px;
padding-bottom: 40px;
}
/*END FOOTER SOCIAL DESIGN*/
.single_footer{}
@media only screen and (max-width:768px) { 
.single_footer{margin-bottom:30px;}
}
.single_footer h4 {
color: #B30000;
margin-top: 0;
margin-bottom: 25px;
font-weight: 700;
text-transform: uppercase;
font-size: 20px;
}
.single_footer h4::after {
content: "";
display: block;
height: 2px;
width: 40px;
background: #B30000;
margin-top: 20px;
}
.single_footer p{color:#fff;}
.single_footer ul {
margin: 0;
padding: 0;
list-style: none;
}
.single_footer ul li{}
.single_footer ul li a {
color: black;
-webkit-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
line-height: 36px;
font-size: 15px;
text-transform: capitalize;
}
.single_footer ul li a:hover { color: #ff3666; }

.single_footer_address{}
.single_footer_address ul{}
.single_footer_address ul li{color:#fff;}
.single_footer_address ul li span {
font-weight: 400;
color: #fff;
line-height: 28px;
}
.contact_social ul {
list-style: outside none none;
margin: 0;
padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
display: block;
position: relative;
margin-top: 15px;
width: 100%;
}
.subscribe__input {
background-color: #fff;
border: medium none;
border-radius: 5px;
color: #333;
display: block;
font-size: 15px;
font-weight: 500;
height: 60px;
letter-spacing: 0.4px;
margin: 0;
padding: 0 150px 0 20px;
text-align: center;
text-transform: capitalize;
width: 100%;
}
@media only screen and (max-width:768px) { 
.subscribe__input{padding: 0 50px 0 20px;}
}

.subscribe__btn {
background-color: transparent;
border-radius: 0 25px 25px 0;
color: #01c7e9;
cursor: pointer;
display: block;
font-size: 20px;
height: 60px;
position: absolute;
right: 0;
top: 0;
width: 60px;
}
.subscribe__btn i{transition: all 0.3s ease 0s;}
@media only screen and (max-width:768px) { 
.subscribe__btn{right:0px;}
}

.subscribe__btn:hover i{
color:#ff3666;
}
button {
padding: 0;
border: none;
background-color: transparent;
-webkit-border-radius: 0;
border-radius: 0;
}
/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {margin-top:40px;}
.social_profile ul{
list-style: outside none none;
margin: 0;
padding: 0;
}
.social_profile ul li{float:left;}
.social_profile ul li a {
text-align: center;
border: 0px;
text-transform: uppercase;
transition: all 0.3s ease 0s;
margin: 0px 5px;
font-size: 18px;
color: #fff;
border-radius: 30px;
width: 50px;
height: 50px;
line-height: 50px;
display: block;
border: 1px solid rgba(255,255,255,0.2);
}
@media only screen and (max-width:768px) { 
.social_profile ul li a{margin-right:10px;margin-bottom:10px;}
}
@media only screen and (max-width:480px) { 
.social_profile ul li a{
width:40px;
height:40px;
line-height:40px;
}
}
.social_profile ul li a:hover{
background:#ff3666;
border: 1px solid #ff3666;
color:#fff;
border:0px;
}
/*END SOCIAL PROFILE CSS*/
.copyright {
padding-top: 40px;
color: black;
font-size: 15px;
border-top: 1px solid black;
text-align: center;
}
.copyright a{
    color:black;
    transition: all 0.2s ease 0s;}
.copyright a:hover{color:black;}

.social img {
    margin-bottom: 0.5rem;
}

.form-control.btn.btn-danger {
    background-color: #b30000 !important;
    border: #b30000 !important;
}

.form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #B30000;
    outline: 0;
    box-shadow: 0 0 0 0.25rem transparent !important;
}

@media (max-width: 575px) {
    .footer .row {
        display: flex;
        flex-wrap: wrap !important;
        margin: 0 auto;
        justify-content: center;
    }
    .footer .row>* {
        width: auto !important;
    }
    .single_footer.single_footer_address > div {
        justify-content: left !important;
    }
}

@media (max-width: 430px) {
    .footer .row>* {
        width: 100% !important;
    }
}

@media (min-width: 800px) {
    .footer {
        padding: initial 10%;
    }
}

.footer {
    margin-top: 3rem;
}