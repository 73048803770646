
#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  #loading-text {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #999;
    width: 100px;
    height: 30px;
    margin: -7px 0 0 -45px;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 20px;
  }
  
  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -95px 0 0 -85px;
    border: 3px solid #F00;
}
  
  #loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid #0F0;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }
  
  #loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid #00F;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }
  
  #loading-content {
    border: 3px solid transparent;
    border-top-color: #B30000;
    border-bottom-color: #B30000;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }
  
  #loading-content:before {
    border: 3px solid transparent;
    border-top-color: #5e0606;
    border-bottom-color: #5e0606;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
  }
  
  #loading-content:after {
    border: 3px solid transparent;
    border-top-color: #d51818;
    border-bottom-color: #d51818;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
  }
  
  @-webkit-keyframes loaders {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  #content-wrapper {
    opacity: 0; /* Commencez avec une opacité de 0 */
    transition: opacity 1s ease-in-out;
    width: 100%;
    height: 100%;
  }
  
  .scrolltop-wrap {
    box-sizing: border-box;
    position: absolute;
    top: 12rem;
    right: 2rem;
    bottom: 0;
    pointer-events: none;
    backface-visibility: hidden;
  }
  
  .scrolltop-wrap a {
    position: fixed;
    position: sticky;
    top: -5rem;
    width: 3rem;
    height: 3rem;
    margin-bottom: -5rem;
    transform: translateY(100vh);
    backface-visibility: hidden;
    display: inline-block;
    text-decoration: none;
    user-select: none;
    pointer-events: all;
    outline: none;
    overflow: hidden;
  }
  
  .scrolltop-wrap a svg {
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: all 0.1s;
  }
  
  .scrolltop-wrap a #scrolltop-arrow {
    transform: scale(0.66);
    transform-origin: center;
  }

  .scrolltop-wrap a #scrolltop-bg {
    fill: #B30000;
  }

  .scrolltop-wrap a #scrolltop-arrow {
    fill: white;
  }
  
  .scrolltop-wrap a:hover #scrolltop-bg {
    fill: #B30000;
    opacity: 0.75;
  }
  
  .scrolltop-wrap a:hover #scrolltop-arrow {
    fill: white;
  }
  
  .scrolltop-wrap a:active #scrolltop-bg {
    fill: lighten(#007bff, 8%);
  }
  
  @media print {
    .scrolltop-wrap {
      display: none !important;
    }
  }
  