.hebergement-content {
  margin: 0 3.5rem;
}

.hebergement-ul {
  margin: 0 3rem;
}

.hebergement-ul li {
  list-style: circle !important;
}