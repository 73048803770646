.nav.nav-tabs {
    display: flex;
    justify-content: space-around;
}

.nav-link.active {
    color: white !important;
    background-color: #B30000 !important;
}

.nav-link {
    color: #2f3542 !important;
}

.tab-pane {
    padding: 40px;
}


#faculte {
    margin-top: 3rem;
}

#faculte .btn.btn-danger {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #B30000 !important;
    border: #B30000;
    padding: 10px;
    margin-bottom: 20px;
}

#faculte .btn.btn-danger:hover {
    opacity: 0.75;
    transition: 0.3s all 0.5s;
}

@media (min-width: 800px) {
    #faculte {
        padding: 0px 10%;
    }
}