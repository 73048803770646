/*
* Menu dropdown
*/
.header ul {
    font-size: 0;
    list-style-type: none;
}

.header ul li {
    font-size: 1rem;
    display: inline-block;
    color: white;
    position: relative;
}

.header ul li ul {
    display: none;
}

.header ul li:hover {
    cursor: pointer;
}

.header ul li:hover ul:after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: white;
    position: absolute;
    left: 10%;
    top: -10px;
    transform: rotate(45deg);
    background-color: #B30000;
    z-index: -1;
}

.header ul li:hover ul {
    display: block;
    margin-top: 15px;
    /* width: 250px;*/
    left: -5%;
    position: absolute;
}

.header ul li:hover ul li {
    display: block;
    background-color: #B30000;
}

.header ul li ul li:hover {
    display: block;
    background-color: black;
    color: #B30000;
}

/*deep second time*/

.header ul li ul li ul {
    display: none !important;
}

.header ul li:hover {
    cursor: pointer;
}

.header ul li ul li:hover ul {
    display: block !important;
    margin-top: 15px;
    width: 250px;
    left: 78%;
    top: -100%;
    position: absolute;
    padding-left: 36px;
}

.header ul li ul li:hover ul {
    display: block;
    /*background-color: #b30000;*/
}

.header ul li ul li li ul:hover {
    display: block;
    background-color: white;
    color: #b30000;
}

.header ul li:hover ul ul:after {
    display: none;
}

.carousel-indicators {
    display: none;
}

nav {
    height: 6rem;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 10;
    padding: 1.5rem;
    /* background-color: #053742;
    box-shadow: 0 3px 20px rgba(0,0,0,0.2); */
}

/* Styling Logo*/

.logo {
    text-align: left;
    width: 25vw;
}

.logo img {
    height: auto;
    width: 5rem;
}

/* Styling Navigation Links*/

.nav-links {
    /*width: 80vw;*/
    display: flex;
    /*padding: 0 0.7vw;*/
    justify-content: space-evenly;
    list-style: none;
    font-weight: 600;
}

.nav-links li+li a {
    margin-left: 5px;
}

.nav-links li a {
    text-decoration: none;
    transition: all ease-in-out 350ms;
    padding: 10px 11px;
    display: block;
    color: white;
    background-color: #B3000050;
}

.nav-links {
    overflow: hidden;
    border-radius: 15px;
}

.nav-links li a:hover, .nav-links li a:focus{
    background-color: #B30000;
}

.nav-links li {
    position: relative;
}

.dropdown .dropdown-menu {
    background-color: #ED3E44;
    display: inline-block;
    text-align: right;
    position: absolute;
    top: 2.5rem;
    right: -10px;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: 160px;
}

.dropdown-menu a {
    color: #fff;
}

.dropdown-menu-item {
    cursor: pointer;
    padding: 1em;
    text-align: center;
}

.dropdown-menu-item:hover {
    background-color: darken(#ED3E44, 5%);
}


/*Buttons Styling*/

.login-button {
    padding: 0.6rem 0.8rem;
    margin-left: 2vw;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: 1.5px solid #f2f5f7;
    border-radius: 2em;
}

.login-button:hover {
    color: #fff;
    background-color: #dd5f24;
    border: 1.5px solid #dd5f24;
    transition: all ease-in-out 350ms;
}

/*Navigation Icon Styling*/

.hamburger div {
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px;
    transition: all 0.3s ease;
}

.hamburger {
    display: none;
}


.header-carousel .active.carousel-item [class^="image"] {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 40rem;
}

/*Responsive Adding Media Queries*/

@media screen and (max-width: 893px) {
    nav {
        position: fixed;
        z-index: 3;
    }

    .hamburger {
        display: block;
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
        transition: all 0.7s ease;
    }

    .nav-links {
        background: #2f3542;
        position: fixed;
        opacity: 1;
        height: 80vh;
        width: 100%;
        flex-direction: column;
        clip-path: circle(50px at 90% -20%);
        -webkit-clip-path: circle(50px at 90% -10%);
        transition: all 1s ease-out;
        pointer-events: none;
    }

    .nav-links.open {
        clip-path: circle(1000px at 90% -10%);
        -webkit-clip-path: circle(1000px at 90% -10%);
        pointer-events: all;
    }

    .nav-links li {
        opacity: 1;
    }

    .nav-links li:nth-child(1) {
        transition: all 0.5s ease 0.2s;
    }

    .nav-links li:nth-child(2) {
        transition: all 0.5s ease 0.4s;
    }

    .nav-links li:nth-child(3) {
        transition: all 0.5s ease 0.6s;
    }

    .nav-links li:nth-child(4) {
        transition: all 0.5s ease 0.7s;
    }

    .nav-links li:nth-child(5) {
        transition: all 0.5s ease 0.8s;
    }

    .nav-links li:nth-child(6) {
        transition: all 0.5s ease 0.9s;
        margin: 0;
    }

    .nav-links li:nth-child(7) {
        transition: all 0.5s ease 1s;
        margin: 0;
    }

    li.fade {
        opacity: 1;
    }

    /* Navigation Bar Icon on Click*/

    .toggle .bars1 {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .toggle .bars2 {
        transition: all 0s ease;
        width: 0;
    }

    .toggle .bars3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

@media (max-width: 942px) {
    .header-carousel .active.carousel-item [class^="image"] {
        height: 600px;
    }
}

@media (max-width: 831px) {
    .demo-text {
        width: 55% !important;
    }

    .nav-links {
        justify-content: normal;
        gap: 5px;
        padding: 50px 15%;
    }

    .nav-links.open {
        background-color: #B30000;
    }

    .nav-links.open li {
        border-bottom: 2px solid #fff;
        margin-left: 0 !important;
    }
}

@media (max-width: 707px) {
    .demo-text {
        width: 60% !important;
    }
}

@media (max-width: 630px) {
    .header-carousel .active.carousel-item [class^="image"] {
        height: 550px;
    }

    .demo-text {
        width: 75% !important;
    }
}

@media (max-width: 510px) {
    .header-carousel .active.carousel-item [class^="image"] {
        height: 400px;
    }

    .demo-text {
        width: 85% !important;
        position: relative !important;
        right: 0% !important;
        margin: 0 auto;
        margin-top: -10rem !important;
    }
}

.demo-text {
    background-color: #B30000;
    opacity: 0.5;
    width: 45%;
    padding: 10px;
    border-radius: 27px;
    position: absolute;
    right: 5%;
    bottom: 5%;
    color: white;
    text-align: center;
}

.demo-texts {
    position: absolute;
    left: 25%;
    top: 50%;
    color: white;
    font-weight: 900;

}