.inscription-content {
  margin: 0 2rem;
}

.dossier_inscription ul {
  margin: 0 3.5rem;
}

#inscription .col-md-4{
  background: url(/src/assets/images/image22.png) no-repeat;
  background-size: cover;
}