.nav.nav-tabs {
    display: flex;
    justify-content: space-around;
}

.nav-link.active {
    color: white !important;
    background-color: #B30000 !important;
}

.nav-link {
    color: #2f3542 !important;
}

.tab-pane {
    padding: 40px;
}

table, th, td {
    border: 1px solid #ccc;
}

.formation-content .nav-link {
    margin-right: 1rem;
}

.formation-content .nav-link i, .faculte-content .nav-link i {
    margin-left: 0.5rem;
}

.formation-content .nav-link i.active-icon, .faculte-content .nav-link i.active-icon  {
    color: white !important;
}

.tab-content li {
    line-height: 2.5;
    list-style-type: none;
}

.tab-content li:before {
    content: '\2022'; 
    color: #b30000; 
    display: inline-block;
    width: 1em; 
    margin-right: 0.5em; 
}

.faculte-content .tab-content li:before {
    content: '\2713'; 
    color: #b30000; 
    display: inline-block;
    width: 1em; 
    margin-right: 0.5em; 
}

@media (min-width: 800px) {
    #formation {
        padding: 0px 10%;
    }
}