.map-content {
  position: relative;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  margin: 0 4rem;
}

.contact-content .contactez-nous {
  margin-top: 2.5rem;
}

.contact-content .contactez-nous h1 {
  color: white;
  font-weight: 900;
  text-transform: uppercase;
}

.contact-content .contactez-nous p {
  color: white;
  margin-left: 4rem;
}

i,
.contact-content .contactez-nous img,
.contact-content .contactez-nous i {
  color: #FF0000;
  margin-right: 1.5rem;
}

.contact-content .contactez-nous h4 {
  color: #FF0000;
  margin-left: 1.5rem;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 0;
}

.horaire_ouverture>div {
  display: inline-block;
  text-align: right;
}

@media (max-width: 981px) {
  .map-wrap {
    height: auto !important;
  }

  .contact-content {
    display: flex;
    flex-direction: column !important;
    margin: 0 !important;
    align-items: center;
    /* justify-content: space-between;
      margin: 0 4rem; */
  }

  .contact-content .col-md-5 {
    width: 100%;
  }

  .contactez-nous {
    display: flex;
    flex-wrap: wrap !important;
    /* justify-content: center; */
  }

  .contact-content .col-md-7 {
    width: 100%;
  }

  .map-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 3rem !important;
  }
}

@media (max-width: 469px) {
  .contact-content .contactez-nous p {
    margin-left: 1rem !important;
  }
}