.content-default {
    display: flex;
    justify-content: space-between;
    margin: auto 1rem;
}

.first-card,
.second-card,
.three-card {
    padding: 10px, 20px, 20px, 20px;
    border-radius: 27px;
    margin-right: 1rem;
}

.second-card,
.three-card {
    margin-top: 3rem;
}

.first-card {
    background-color: #B30000;
    opacity: 0.75;
}

.first-card img,
.second-card img,
.three-card img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.title-card {
    color: white;
    text-align: center;
}

.first-card p,
.second-card p,
.three-card p {
    padding: 20px;
}

.first-card p {
    color: white;
}

.map-wrap {
    position: relative;
    height: 500px;
    background-image: url('/src/assets/images/IMG-0947.JPG');
}

.map-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: #7f0000de;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

#apropos .row {
    margin: 0 1rem;
}

.image-apropos {
    background: url(/src/assets/images/image22.png) no-repeat;
    background-size: cover;
}

#partenaire {
    margin-top: 4rem;
}

#partenaire .wrapper-carousel {
    margin: 0 1rem;
}

#partenaire .wrapper-carousel img {
    width: 80%;
}


.slick-slider.slick-initialized {
    padding: 20px;
    width: 95% !important;
    margin-left: 1rem !important;
}

.slick-arrow.slick-prev {
    background-color: #2f3542;
    border-radius: 50%;
}

.slick-arrow.slick-next {
    background-color: #2f3542;
    border-radius: 50%;
}

.wrapper-carousel {
    display: flex !important;
    justify-content: space-between !important;
}

.image-animateur {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#animation-programme .row.d-flex {
    align-items: center;
}

.emoji-main {
    font-size: 3.5em;
}

.programme li {
    line-height: 2;
    list-style-type: none;
}

.programme li:before {
    content: '\2022';
    color: #b30000;
    display: inline-block;
    width: 1em;
    margin-right: 0.5em;
}

.faculte-content .tab-content li {
    text-decoration: underline;
}

#partenaire img {
    width: 80%;
    height: 110px;
}

@media (max-width: 767px) {
    .image-animateur {
        display: flex;
        margin: 0 auto;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 480px) {
    #partenaire img {
        width: 100% !important;
    }

    #partenaire .slick-slider.slick-initialized {
        margin: auto 0.3rem !important;
    }

    #partenaire .wrapper-carousel {
        margin: 0 !important;
    }
}

@media (min-width: 800px) {
    #animation-programme {
        padding: 0px 10%;
    }
}